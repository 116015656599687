<template>
  <div class="show-package">
    <loading v-if="isLoading" />
    <div v-else>
      <b-row class="academic-courses-header">
        <p class="academic-courses-title">
          {{ data.name }}
        </p>
        <hr class="border-2" />
      </b-row>
      <explore-course-card-component
        :coursesData="data.courses"
        :canAddToCart="false"
        :isPackage="true"
      />
    </div>
  </div>
</template>
<script>
import ExploreCourseCardComponent from "@/components/Explore/CourseCard/ExploreCourseCardComponent.vue";
import loading from "@/components/loading/loading.vue";
import { BRow } from "bootstrap-vue";

export default {
  name: "ShowPackage",
  components: {
    BRow,
    loading,
    ExploreCourseCardComponent,
  },
  data() {
    return {
      isLoading: null,
      data: [],
    };
  },
  created() {
    this.getPackageCourses();
  },
  methods: {
    async getPackageCourses() {
      this.isLoading = true;
      try {
        const _id = this.$route.params.id;
        const response = await this.$http.get(`guardian/packages/${_id}`);
        if (response.status === 200 || response.status === 201) {
          this.data = response.data.data;
        }
      } catch (error) {
        console.log(error);
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
